<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="订单列表"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-dropdown-menu class="van-hairline--bottom" active-color="#1989fa">
      <van-dropdown-item
        v-model="state.order_status"
        :options="allStatus"
        @change="onStatusChange"
      />
      <van-dropdown-item title="设备" ref="treeItem" v-if="agent.level == 2">
        <van-tree-select
          v-model:active-id="state.activeDeviceID"
          v-model:main-active-index="state.activeStationIndex"
          :items="state.allStation"
          @click-nav="selectStation"
          @click-item="selectDevice"
        />
        <div class="text-center">
          <van-button
            type="default"
            style="width: 50%; border-radius: 0"
            @click="onReset"
          >
            重置
          </van-button>
          <van-button
            type="primary"
            style="width: 50%; border-radius: 0"
            @click="onConfirm"
          >
            确认
          </van-button>
        </div>
      </van-dropdown-item>
      <van-dropdown-item title="日期" @open="state.showCalendar = true" />
    </van-dropdown-menu>
    <van-calendar
      v-model:show="state.showCalendar"
      type="range"
      position="right"
      color="#1989fa"
      :allow-same-day="true"
      :min-date="new Date(new Date() - 2 * 15552000000)"
      :max-date="new Date()"
      :round="false"
      @confirm="onDateRangeConfirm"
    />
    <van-list
      v-model:loading="state.loading"
      v-model:error="state.error"
      :finished="state.finished"
      finished-text="没有更多了"
      error-text="拉取失败，点击重新加载"
      @load="loadPage"
    >
      <van-cell
        v-for="(item, index) in state.items"
        :key="index"
        :title="formatDateTime(item.created_at)"
        :to="{
          name: 'AgentChargingOrderDetail',
          query: { order_id: item.order_id },
        }"
        is-link
        center
      >
        <template #title>
          <div class="d-flex">
            <van-image
              style="margin-top: 8px"
              width="32px"
              height="32px"
              round
              :src="getUser(String(item.user_id)).headimgurl"
            />
            <div class="ml-1">
              <div>
                {{ getUser(String(item.user_id)).nickname }}
              </div>
              <div class="small">{{ formatDateTime(item.created_at) }}</div>
            </div>
          </div>
        </template>
        <template #value>
          <span v-if="item.order_status == 3" class="text-success"
            >{{ new Number(item.total_amount * 0.01).toFixed(2) }}元</span
          >
          <span v-else-if="item.order_status == 1" class="text-success"
            >充电中</span
          >
          <span v-else-if="item.order_status == 2" class="text-warning"
            >待结算</span
          >
          <span v-else-if="item.order_status == 4" class="text-info"
            >已关闭</span
          >
          <span v-else-if="item.order_status == 0" class="text-primary"
            >已创建</span
          >
          <span v-else class="text-secondary">未知</span>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  Calendar,
  DropdownMenu,
  DropdownItem,
  List,
  Toast,
  TreeSelect,
  Image,
} from "vant";
import { getChargingOrderLog } from "@/api/order.service";
import { getDeviceList, getUnbindDeviceList } from "@/api/device.service";
import Moment from "moment";
import { useStore } from "vuex";
export default {
  components: {
    [List.name]: List,
    [Image.name]: Image,
    [Calendar.name]: Calendar,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [TreeSelect.name]: TreeSelect,
  },

  setup() {
    const store = useStore();
    const agent = store.getters.agentInfo;
    const treeItem = ref(null);
    const state = reactive({
      page: 1,
      items: [],
      loading: false,
      finished: false,
      showCalendar: false,

      // 搜索表单字段
      order_status: -1,
      allStation: [],
      activeStationIndex: 0, // 选中电站的索引
      activeDeviceID: "0_0", // 电站ID_设备ID
      start_date: "",
      end_date: "",
    });

    const allStatus = [
      { text: "全部", value: -1 },
      { text: "已创建", value: 0 },
      { text: "充电中", value: 1 },
      { text: "未结算", value: 2 }, // 已结束
      { text: "已结算", value: 3 },
      // { text: "已关闭", value: 4 },
    ];

    const users = new Map();
    const getUser = (user_id) => {
      const user = users.get(String(user_id));
      return user ? user : null;
    };

    const loadPage = async () => {
      state.loading = true;
      if (state.page == 1) {
        state.items = [];
      }
      try {
        const params = state.activeDeviceID.split("_");
        const { result } = await getChargingOrderLog({
          page: state.page,
          order_status: state.order_status,
          station_id: params[0],
          device_id: params[1],
          start_date: state.start_date,
          end_date: state.end_date,
        });
        if (result.items.length > 0) {
          state.items.push(...result.items);
          state.page++;
        }
        if (result.users.length > 0) {
          for (const user of result.users) {
            if (!users.has(user.id)) {
              users.set(user.id, user);
            }
          }
        }
        if (!result.hasNext || result.items.length == 0) {
          state.finished = true;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.error = true;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const formatDateTime = (time) => {
      return Moment(time * 1000).format("YYYY-MM-DD HH:mm:ss");
    };

    const loadDeviceList = async () => {
      try {
        // 获取已绑定的设备, 返回的数据是按电站分组, 如果为空表示没有电站, 就必须继续查询了
        const { result } = await getDeviceList();
        if (!result.items.length) {
          return;
        }

        // 获取已解绑的设备, 并将其整理到 Map() 中, 备用
        // 按电站ID区分, 前提是电站不能删, 如果删了电站, 解绑的设备就无法展示出来作为搜索条件了
        const unbindDevices = new Map();
        const rs = await getUnbindDeviceList();
        rs.result.items.map((item) => {
          const { station_id, devices } = item;
          unbindDevices.set(station_id, devices);
        });

        // 组装 TreeSelect 组件所需数据
        state.allStation = [];
        state.allStation.push({
          id: 0,
          text: "所有充电站",
          children: [{ text: "所有设备", id: "0_0" }],
        });

        result.items.map((item) => {
          const { station_id, station_name, devices } = item;
          const children = [{ text: "所有设备", id: `${station_id}_0` }];
          const bindDeviceIds = new Set(); // 如果某个设备在同一电站解绑了又绑定回来了, 这种情况下用于排除解绑设备中相应的设备
          if (devices.length > 0) {
            devices.map((device) => {
              bindDeviceIds.add(device.id);
              children.push({
                text: `${device.name}(...${device.pile_code.substring(7)})`,
                id: `${station_id}_${device.id}`,
              });
            });
          }

          const unbinds = unbindDevices.get(station_id);
          if (unbinds) {
            unbinds.map((device) => {
              if (bindDeviceIds.has(device.id)) {
                return;
              }
              children.push({
                text: `${device.name}(...${device.pile_code.substring(
                  7
                )} 已解绑)`,
                id: `${station_id}_${device.id}`,
              });
            });
          }

          state.allStation.push({
            id: station_id,
            text: station_name,
            children,
          });
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        console.log(msg ? msg : error);
      }
    };

    const selectStation = (index) => {
      state.activeStationIndex = index;
      state.activeDeviceID = `${state.allStation[index].id}_0`;
    };

    const selectDevice = (data) => {
      state.activeDeviceID = data.id;
    };

    const onReset = () => {
      state.page = 1;
      state.activeStationIndex = 0;
      state.activeDeviceID = "0_0";
      treeItem.value.toggle();
      loadPage();
    };

    const onConfirm = () => {
      state.page = 1;
      treeItem.value.toggle();
      loadPage();
    };

    const onStatusChange = () => {
      state.page = 1;
      loadPage();
    };

    const formatDate = (date) =>
      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    const onDateRangeConfirm = (values) => {
      const [start, end] = values;
      state.start_date = formatDate(start);
      state.end_date = formatDate(end);
      state.showCalendar = false; // 提示: 这样会导致日历立即隐藏, 没有动画
      state.page = 1;
      loadPage();
    };

    onMounted(() => {
      if (agent.level == 2) {
        loadDeviceList();
      }
    });

    return {
      agent,
      state,
      loadPage,
      formatDateTime,
      allStatus,
      treeItem,
      selectStation,
      selectDevice,
      onReset,
      onConfirm,
      onStatusChange,
      onDateRangeConfirm,
      getUser,
    };
  },
};
</script>
